import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'
import { Button, Grid, Typography } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { Fragment, useState } from 'react'
import { StringListInput } from '../../Components/inputs/StringListInput'
import { menuStyles } from './ReportFormStyle'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { isValidEmail } from '../../Components/inputs/CustomValidators'

export interface IReportFormData {
    month: string;
    year: string;
    emails: string[];
}

export const ReportForm = (props: any) => {
    const styles = menuStyles();

    const [emails, setEmails] = useState<string[]|[]>([]);
    const [month, setMonth] = useState<number| undefined>(new Date().getMonth());
    const [year, setYear] = useState<number | undefined>(new Date().getFullYear());
    const [date, setDate] = useState<Date| undefined>(new Date());

    const addEmail = (email: string) => setEmails([...emails, email]);
    const removeEmail = (email: string) => setEmails(emails.filter((em: string) => em !== email));

    return (
        <Fragment>
            <Grid container className={styles.mainFeaturedPost}>
                <Grid item lg={4} xl={4} md={6} sm={10} xs={10} className={styles.separator}>
                    <Typography variant="h3" color="inherit">
                        {'Generar Reportes'}
                    </Typography>
                    <Typography variant="h5" color="inherit" paragraph>
                        {'Rellene los siguientes datos para generar el reporte'}
                    </Typography>                    
                </Grid>                
                <Grid item lg={12} xl={12} md={12} sm={12} xs={12} className={styles.separator}></Grid>
                <Grid item lg={4} xl={4} md={4} sm={4} xs={10}>
                    <Typography variant='h6' component='p' >
                        Seleccione el mes
                    </Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <DatePicker
                        margin="normal"
                        id="month-picker"
                        // label="Theese are our available dates"
                        format="MMMM"
                        value={date}
                        openTo='month'
                        views={["month"]}
                        disableFuture={true}                        
                        // KeyboardButtonProps={{
                        //     'aria-label': 'change date',
                        // }}
                        // disablePast={true}
                        // shouldDisableDate={(date) => 
                        //     {
                        //         if(date !== null && (date.getDay() === 1 || date.getDay() === 0))
                        //             return true;
                        //         return false;
                        //     }}
                        onChange={(val: MaterialUiPickersDate)=> {
                            setMonth(val?.getMonth());
                            setDate(new Date((val || new Date().toDateString())));
                        }}
                    />
                    </MuiPickersUtilsProvider>                
                </Grid>
                <Grid item lg={12} xl={12} md={12} sm={12} xs={12} className={styles.separator}></Grid>
                <Grid item lg={4} xl={4} md={4} sm={4} xs={10}>
                    <Typography variant='h6' component='p'>
                        Seleccione el año
                    </Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        margin="normal"
                        id="year-picker"
                        format="yyyy"
                        value={date}
                        openTo='year'
                        views={["year"]}
                        disableFuture={true}
                        onChange={(val: MaterialUiPickersDate)=> {
                            setYear(val?.getFullYear());
                            setDate(new Date((val || new Date().toDateString())));
                        }}
                    />
                    </MuiPickersUtilsProvider>                
                </Grid>
                <Grid item lg={12} xl={12} md={12} sm={12} xs={12} className={styles.separator}></Grid>
                <Grid item lg={4} xl={4} md={4} sm={4} xs={10}>
                    <Typography variant='h6' component='p'>
                        Agregue los correos a los que enviar el reporte 
                    </Typography>
                    <StringListInput 
                        deleteParentHandler={removeEmail} 
                        keyDownParentHandler={addEmail}
                        validator={isValidEmail}
                        message='Dirección de correo inválida'
                    />
                </Grid>
                <Grid item lg={12} xl={12} md={12} sm={12} xs={12} className={styles.separator}></Grid>
                <Grid item lg={3} xl={3} md={3} sm={3} xs={3}  style={{
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    alignContent: 'flex-start'
                }}>
                    <Button 
                        variant='contained' 
                        type="submit" 
                        color={'secondary'} 
                        onClick={() => props.makeForm({month: (month||0) + 1, year, emails})}
                        disabled={emails && emails.length === 0}
                    >
                        Listo
                    </Button>
                </Grid>
            </Grid>
        </Fragment>
    )
}


import APP_ACTIONS from './../Domain/ActionTypes'

const initialState = {
    userName: '',
    accessToken: '',
    userId: '',
};

const UserReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case APP_ACTIONS.LOGIN:
            console.log('reducer received: ', action);
            return {
                ...state,
                userName: action.payload.userName,
                accessToken: action.payload.token,
                userId: action.payload.id
            };

        case APP_ACTIONS.LOGOUT:
            return initialState;
    
        default:
            return state;
    }
}

export default UserReducer;
import { connect } from 'react-redux'
import Report from './../Actions/Report'
import { ReportForm, IReportFormData } from '../Pages/reportForm/ReportForm'
import { ReportAPI } from '../Api/ApiService'
import { toast } from 'react-toastify';

const mapStateToProps = (state: any) => ({
    reportMessage: state.report.reportMessage
});

const mapDispatchToProps = (dispatch: any) => ({
    makeForm: async (formData: IReportFormData) => {
        try{            
            await ReportAPI.makeReport(formData);
            toast.success('El reporte fue enviado correctamente.');
        }
        catch {
            console.log('Ocurrio un problema tratando de generar reporte');
        }
    },
    refresh: () => {
        dispatch(Report.refresh());
    }
});

const ReportFormConnector = connect(mapStateToProps, mapDispatchToProps)(ReportForm);
export default ReportFormConnector;
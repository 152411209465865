import { Avatar, Chip, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'

interface IProps {
    deleteParentHandler: (email: string) => void;
    keyDownParentHandler: (email: string) => void;
    validator?: any;
    message?: string
}

export const StringListInput = ({deleteParentHandler, keyDownParentHandler, validator, message}:IProps) => {

    const [emails, setEmails] = useState<string[]|[]>([]);
    const [currentEmail, setCurrentEmail] = useState('');
    const [valid, setValid] = useState(true);

    const handleKeyDown = (e: any) => {
        if(e.key === 'Enter')
        {
            if(!validator || validator(currentEmail))
            {
                setEmails([...emails, currentEmail]);
                setCurrentEmail('');
                keyDownParentHandler(currentEmail);
            }
            else
                setValid(false);            
        }
        else
            setValid(true);
    };

    const handleDelete = (email: string) => {
        setEmails(emails.filter(em => em !== email));
        deleteParentHandler(email);
    };

    return (
        <div>
            {                
                emails.map((email: string) => (
                    <Chip
                        key={email}
                        avatar={<Avatar>{email[0]}</Avatar>}
                        label={email}
                        clickable
                        color="secondary"
                        onDelete={() => handleDelete(email)}
                        style={{marginTop: '5px', marginLeft: '5px'}}
                    />
                ))
            }
            <TextField
                id="filled-full-width"
                placeholder="Emails"
                helperText="Presione Enter para agregar"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
                value={currentEmail}         
                onChange={(e) => { setCurrentEmail(e.target.value); }}
                onKeyDown={handleKeyDown}               
            />
            {   !valid &&
                <Typography variant='subtitle2' style={{ color: 'rgb(255,0,0)' }}>
                    {message || 'Valor invalido'}
                </Typography>
            }
        </div>
    )
}

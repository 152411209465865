// import {useDispatch, useStore} from 'react-redux'
import APP_ACTIONS from './../Domain/ActionTypes'

export interface ICredential{
    userName: string,
    password: string,
};

// const dispatch = useDispatch();
// const getState = useStore().getState;

const auth = {
    login: (credentials: any) => {
        return {
            type: APP_ACTIONS.LOGIN,
            payload: { 
                id: credentials.id, 
                userName: credentials.username,
                token: credentials.token
            }
        };
    },
    logout: () => {
        return {
            type: APP_ACTIONS.LOGOUT            
        };
    }
}

export default auth;
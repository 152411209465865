import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { ICredential } from '../Actions/Auth';
import { IReportFormData } from '../Pages/reportForm/ReportForm';

axios.defaults.baseURL = process.env.REACT_APP_BACK_URL;

// axios.interceptors.request.use((config)=>
// {
//     const token = window.localStorage.getItem('jwt');
//     if(token) config.headers.Authorization = `bearer ${token}`;
//     return config;
// }, error=> Promise.reject(error));

axios.interceptors.response.use(undefined, error=> {
    console.log('response error: ', error.message);
    if(error.message === 'Network Error' && !error.content)
    {
        toast.error('Network Failed');
        throw error.message;
    }
    const {status} = error.response;
    if(status === 401)
    {        
        toast.error('Not Authorized');
        //throw error.response;
    }    
    if(status === 400)
    {
        toast.error('Not Found');
    }
    if(status === 500)
    {
        toast.error('Internal Server Error: ', error.message);        
    }

    throw error.response;
});

const responseBody = (response: AxiosResponse) => response;
const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    del: (url: string) => axios.delete(url).then(responseBody), 
    postFrom: (url: string, file:Blob) => { //to upload files
        let formData = new FormData();
        formData.append('File', file);
        return axios.post(url, formData, {
            headers: {'Content-type': 'multipart/form-data'}
        }).then(responseBody);
    }
};

const ReportAPI = {
    makeReport: (reportData: IReportFormData) => requests.post('report', reportData),
}

const AuthAPI = {
    login: (credentials: ICredential) => requests.post('Auth/login', credentials),
} 

export { 
    ReportAPI,
    AuthAPI,
};
import { Tab, Tabs } from '@material-ui/core';
import React, { useEffect } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from 'react-router-dom';
import APP_ROUTES from './../../routes';
import { navBarStyles } from './ThemeStyles';

export const NavBarTabs = ({userAuth}) => {
    const [value, setValue] = React.useState(0);
    const history = useHistory();

    useEffect(() => {
        try {
            const tab = localStorage.getItem('currentTab');
            setValue(Number(tab));
        } catch (error) {
            console.log('no tab saved')
        }
    }, [value])

    const handleChange = (val:number, to: string) => {
        setValue(val);
        localStorage.setItem('currentTab', String(val));
        history.push(to);
    };

    const styles = navBarStyles();

    return (
        <Tabs className={styles.hideable} value={value}>
            <Tab          
                label="Home"
                icon={<HomeIcon/>}
                onClick={() => handleChange(0, APP_ROUTES.homePage)}
            />
            {userAuth &&
                <Tab          
                    label="Reportes"
                    icon={<EmailIcon/>}
                    onClick={() => handleChange(1, APP_ROUTES.reports)}
                />
            }
        </Tabs>
    )
}
   
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Store from './Store/Store';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './Domain/BaseStyles';
import 'react-toastify/dist/ReactToastify.min.css';

ReactDOM.render(
  <StrictMode>
    <Provider store={Store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>          
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </StrictMode>,  
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { Grid, Typography } from '@material-ui/core';
import React, { Fragment } from 'react'
import { menuStyles } from './MainMenuStyles'

export const MainMenu = () => {

    const styles = menuStyles();

    return (
        <Fragment>
            <Grid container className={styles.mainFeaturedPost}>
                <Grid item lg={4} xl={4} md={6} sm={10} xs={10} className={styles.separator}>
                    <Typography variant="h3" color="inherit">
                        {'Generador de Reportes'}
                    </Typography>
                    <Typography variant="h5" color="inherit" paragraph>
                        {'ASR Agencia Suscripción de Riesgos'}
                    </Typography>                    
                </Grid>            
            </Grid>
        </Fragment>
    )
}

import { makeStyles } from '@material-ui/core';
import React from 'react'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    footer: {
      backgroundColor: theme.palette.background.paper,
      // marginTop: theme.spacing(24),
      display: 'flex',
      margin: 'auto',
      marginTop: '80px',
      marginBottom: '20px',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'flex-end'
    },
  }));

export const Footer = () => {

    const classes = useStyles();

    return (
        <footer className={classes.footer}>
          <Container maxWidth="lg">
            <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
              ASR Agencia Suscripción de Riesgos-
              {new Date().getFullYear()}
            </Typography>
          </Container>
        </footer>
      );
}

import React, {useState} from 'react'
import { Button, ButtonGroup, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { Form as FinalForm, Field } from 'react-final-form';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useHistory } from 'react-router';
import APP_ROUTES from '../../routes';
import { styles as getStyles } from './LoginFormStyles';
import BasicInput from '../../Components/inputs/BasicInput';
import { combineValidators, isRequired, hasLengthGreaterThan, composeValidators } from 'revalidate';
import { FORM_ERROR } from 'final-form';

export const LoginForm = (props: any) => {

    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const styles = getStyles();

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      };

    const submitHandler = async (data: any) => {
        const result = await props.login(data).catch((err: any) => {
          
          console.log('catched: ', err);

          return {
            [FORM_ERROR]: 'Login Failed'
        };
    });

        if(result && result.status === 200)
            history.push(APP_ROUTES.homePage);
        return result;
    };

    const validator = combineValidators({
        userName: isRequired({ message: 'Nombre de usuario requerido' }),
        password: composeValidators(
            isRequired({ field: 'password', message: 'Contraseña requerida' }),
            hasLengthGreaterThan(3)({ field: 'password', message: 'La contraseña debe tener al menos 4 caracteres' })
        )(),
    });

    return (
        <Grid  
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Grid item lg={12} xl={12} md={12} sm={12} xs={12} className={styles.separator}></Grid>

            <Grid item>
                <Typography variant='h5'>
                    Introduzca sus credenciales
                </Typography>
            </Grid>

            <Grid item lg={12} xl={12} md={12} sm={12} xs={12} className={styles.separator}></Grid>

            <Grid container item
                direction="row"
                justify="center"
                alignItems="center"
            >
                <FinalForm 
                    onSubmit = {submitHandler}
                    validate = {validator}
                    render = {({ handleSubmit, hasValidationErrors, pristine, submitError, modifiedSinceLastSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid item>
                            <Field
                                name='userName'
                                className={styles.inputFields}
                                label='Nombre de Usuario'
                                variant='outlined'
                                component={BasicInput}
                                autocomplete="off"
                            />
                        </Grid>
                    
                        <Grid item>
                            <Field
                                autocomplete="off"
                                label='Contraseña'
                                variant='outlined'
                                className={styles.inputFields}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={()=> {setShowPassword(!showPassword)}}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                type={showPassword? 'text': 'password'}
                                component={BasicInput}
                                name='password'
                            />
                        </Grid>

                        {submitError && !modifiedSinceLastSubmit &&
                            <Grid item>
                                <Typography variant='subtitle2' style={{color: 'rgb(255,0,0)'}}>
                                    {submitError}
                                </Typography>
                            </Grid>
                        }
                        
                        <Grid item>
                            <ButtonGroup fullWidth>
                                <Button className={styles.cancelButton} onClick={() => history.push(APP_ROUTES.homePage)}>
                                    Cancel
                                </Button>
                                <Button 
                                    type="submit" 
                                    className={styles.dialogButton} 
                                    disabled={hasValidationErrors || pristine || (submitError && !modifiedSinceLastSubmit)}>
                                    Submit
                                </Button>
                            </ButtonGroup>   
                        </Grid>       
                    </form>
                    )}
                />
            </Grid>
        </Grid>    
    )
}

import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const navBarStyles = makeStyles((theme: Theme) => createStyles({
  hideable: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
      display: 'block',
      }
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      marginLeft: '85%',
      position: 'absolute',
      width: 'auto',
    },
  },
  menuIconMobile: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
  },
  elemToRigth: {
    display: 'block',
    marginLeft: '75%',
    position: 'absolute',
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '85%'
    }
  },
  sectionMobile: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    marginRight: theme.spacing(2),
    marginLeft: '65%',
  },
  labelButtom: {
    color: theme.palette.secondary.main
  }
}));
import APP_ACTIONS from './../Domain/ActionTypes'

const initialState = {
    reportSended: false,
};

const ReportReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case APP_ACTIONS.MAKE_REPORT:
            return {
                ...state,
                reportSended: action.payload.result
            };
    
        default:
            return state;
    }
}

export default ReportReducer;
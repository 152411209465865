import { connect } from 'react-redux'
import HeaderNavBar from './../Components/nav/HeaderNavBar'

const mapStateToProps = (state: any) => {
    return ({
        userName: state.user.userName,
        token: state.user.accessToken
    });
};

const mapDispatchToProps = (dispatch: any) => ({
});

const HeaderNavBarConnector = connect(mapStateToProps, mapDispatchToProps)(HeaderNavBar);
export default HeaderNavBarConnector;


import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
      primary: {
        main: "rgb(14, 12, 33)"
      },      
      secondary: {
        main: "rgb(234, 116, 0)"
      },
      info: {
        light: "rgb(172, 172, 179)",
        main: "rgb(172, 172, 179)" //#7986cb
      },      
    },
    typography: {
      allVariants:{
        fontFamily: "Barlow Condensed"
      },
    },
    shape: {
      borderRadius: 0
    }
});
export default theme;
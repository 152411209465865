import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const styles = makeStyles((theme: Theme) => createStyles({
    dialogButton: {
        color: theme.palette.success.dark
    },
    cancelButton: {
        color: theme.palette.error.dark
    },
    inputFields: {
        marginBottom: '10px',
        width: '100%',
        "& .MuiOutlinedInput-input": {
            backgroundColor: "white",
            color: "black"
          },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "white",
            color: "black"
          }
    },
    separator: {
        marginTop: 15,
        marginBottom: 15
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
      },  
  }));
import APP_ACTIONS from '../Domain/ActionTypes'

const Report = {
    makeReport: (result: boolean) => {
        return {
            type: APP_ACTIONS.MAKE_REPORT,
            payload: {result}
        };
    },
    refresh: () => {
        return {
            type: APP_ACTIONS.REFRESH_REPORTER
        };
    },
}

export default Report;
import {Redirect, Route} from 'react-router-dom';
import React from 'react';
import APP_ROUTES from './routes';
import { MainMenu } from './Pages/mainMenu/MainMenu';
import LoginFormConnector from './Containers/LoginFormConnector';
import ReportFormConnector from './Containers/ReportFormConnector';

const configRoutes = (auth: boolean) => (
    <>
        <Route exact path={APP_ROUTES.homePage}  component={MainMenu} />
        <Route exact path={APP_ROUTES.login}  component={LoginFormConnector} />
        { auth && <Route exact path={APP_ROUTES.reports}  component={ReportFormConnector} />}
        <Redirect to={APP_ROUTES.homePage}/>
    </>    
)
export default configRoutes;
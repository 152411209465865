import { connect } from 'react-redux'
import { AuthAPI } from '../Api/ApiService';
import auth, { ICredential } from './../Actions/Auth'
import { LoginForm } from './../Pages/loginForm/LoginForm'

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
    login: async (credentials: ICredential) => {
        const response = await AuthAPI.login(credentials);
        dispatch(auth.login(response.data));
        return response;
    }
});

const LoginFormConnector = connect(mapStateToProps, mapDispatchToProps)(LoginForm);
export default LoginFormConnector;
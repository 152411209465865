import React, { Component, Fragment } from 'react';
import './App.css';
import { CssBaseline } from '@material-ui/core';
import { Footer } from './Components/footer/Footer';
import configRoutes  from './routes-config';
import HeaderNavBarConnector from './Containers/HeaderNavBarConnector';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';

class App extends Component
{
	render(){
		console.log('--> ', this.props);
		return(		
			<Fragment>
				<ToastContainer position='bottom-right'/>
				<CssBaseline/>
				<HeaderNavBarConnector/>
				{configRoutes(this.props.isAuth)}
				<Footer/>
			</Fragment>
		)
	}	
}

const mapStateToProps = (state: any) => {
    return ({
        isAuth: Boolean(state.user.accessToken)
    });
};

export default connect(mapStateToProps, null)(App);

import { Badge, IconButton, Menu, MenuItem } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import React from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import APP_ROUTES from './../../routes';

interface IProps {
    anchorElement: HTMLElement | null;
    idElem: string;
    onCloseHandler: ()=>void;
    isOpen: boolean;
    userAuth: boolean;
}

export const NavBarMobilTabs = ({anchorElement, idElem, onCloseHandler, isOpen, userAuth}: IProps) => {

    return (
        <Menu
            anchorEl={anchorElement}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={idElem}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isOpen}
            onClose={onCloseHandler}
        >
            <NavLink to={APP_ROUTES.homePage} style={{color:'inherit', textDecoration:'none' }}> 
                <MenuItem onClick={onCloseHandler}>
                    <IconButton aria-label="Serendipity Ico" color="inherit">
                        <Badge badgeContent={0} color="secondary">
                        <HomeIcon/>
                        </Badge>
                    </IconButton>
                    <p>Home</p>
                </MenuItem>
            </NavLink>

            {userAuth &&
                <NavLink to={APP_ROUTES.reports} style={{color:'inherit', textDecoration:'none' }}> 
                    <MenuItem onClick={onCloseHandler}>
                        <IconButton aria-label="Serendipity Ico" color="inherit">
                            <Badge badgeContent={0} color="secondary">
                            <EmailIcon/>
                            </Badge>
                        </IconButton>
                        <p>Reportes</p>
                    </MenuItem>
                </NavLink>
            }
        </Menu>    
    )
}
import { makeStyles } from "@material-ui/core";

export const menuStyles = makeStyles((theme) => ({
    mainFeaturedPost: {
      alignContent: 'center',
      justifyContent: 'center',      
    },
    separator: {
      marginTop: 15,
      marginBottom: 15
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,.3)',
    },
    mainFeaturedPostContent: {
      position: 'relative',
      padding: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
  }));